<template>
  <div id="home">
        <navbar homepage="false"></navbar>
            <div class="newsletter-container">
                <div class="newsletter-left-column">
                    <h3>Archive</h3>
                    <ul>
                        <li>
                            2018
                            <ul>
                                <li><a class="archive-date" href="01-jan-2018">January</a></li>
                                <li><a class="archive-date" href="01-feb-2018">February</a></li>
                                <li><a class="archive-date" href="01-mar-2018">March</a></li>
                                <li><a class="archive-date" href="01-apr-2018">April</a></li>
                                <li><a class="archive-date active" href="01-may-2018">May</a></li>
                                <li><a class="archive-date" href="01-jun-2018">June</a></li>
                                <li><a class="archive-date" href="01-jul-2018">July</a></li>
                                <li><a class="archive-date" href="01-aug-2018">August</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="newsletter-right-column">
                    <div class="newsletter-right-column-content">
                        <h2>MUSKLE Monthly Newsletter #005</h2>
                        <p class="newsletter-subtitle">
                            "Immune Health”
                        </p>
                        <p class="newsletter-date">
                            (May 2018)
                        </p>
                        <p class="newsletter-paragraph">
                            With a partner in pharmacy, I hear all about the number of people coming in for a flu shot. It’s getting to that time of year where sick leave is increasing, and sniffly noses are as common as the complaints about the weather. But what can we do to reduce the risk of contracting the flu? Firstly, we’ve got to understand how the body identifies the bad guys (Antigens). Then we need to learn how to combat them.
                        </p>
                        <p class="newsletter-paragraph">
                            Our adaptive immune system finds antigens with lymphocytes (a type of white blood cell) in your blood. Each lymphocyte can meet and identify specific antigens and call in the cavalry if it’s deemed an intruder, to fight the infection. Each lymphocyte can identify thousands of different unique antigens, meaning we can remember trillions of different antigens. We’re identifying and fighting infections all day!
                        </p>
                        <p class="newsletter-paragraph">
                            <strong>Boosting Lymphocytes:</strong>
                        </p>
                        <p class="newsletter-paragraph">
                            Continuing from last month, a healthy diet is paramount in fighting infection. Hygiene and physical activity are also big players in keeping healthy.
                        </p>
                        <p class="newsletter-paragraph no-margin">
                            <strong>Foods to consume:</strong>
                            <ul class="styled-list">
                                <li>
                                    Lean protein such as: chicken breast, eggs, fish and beans.
                                </li>
                                <li>
                                    High beta carotene such as: sweet potato, spinach and carrots.
                                </li>
                                <li>
                                    High vitamin C, zinc and vitamin D foods.
                                </li>
                            </ul>
                        </p>
                        <p class="newsletter-paragraph no-margin">
                            <strong>Foods to avoid:</strong>
                            <ul class="styled-list">
                                <li>
                                    High saturated and trans fats such as: Fast food, pastries and
                                </li>
                                <li>
                                    High sugar foods (shock horror)
                                </li>
                            </ul>
                        </p>
                        <p class="newsletter-paragraph no-margin">
                            <strong>Activities to do:</strong>
                            <ul class="styled-list">
                                <li>
                                    Wash your hands regularly. Protection from microbes and antigens must first start outside the body. Keeping your hands clean can stop the spread of infection before you even get it.
                                </li>
                                <li>
                                    Exercise every day (I love this one). Keeping the blood circulating means your lymphocytes are going to be seeing the world! More chance of catching infections before they get out of control. A 30-minute walk is all it takes.
                                </li>
                                <li>
                                    Stop smoking and limit alcohol consumption as these activities stress the body.
                                </li>
                            </ul>
                        </p>
                        <p class="newsletter-paragraph">
                            I can guarantee the office cold will be a thing of the past if you stick to this guide during the winter months.
                        </p>
                        <p class="newsletter-preview">
                            Next month sneak peak - ambient temperature
                        </p>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#home {
  margin: 0;
  padding: 0;
}
.styled-list {
                list-style-type: circle;
                padding-top: 0;
                margin-top: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
            }
  h2 {
      font-family: 'Roboto';
      margin-bottom: 25px;
      text-align: center;
  }
  p {
      font-family: 'Roboto', sans-serif;
  }
  ul {
      list-style-type: none;
  }
  li {
      padding: 5px 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
  }
  .archive-date {
      color: #000;
      text-decoration: none;
  }
  .archive-date:hover {
      text-decoration: underline;
  }
  .active {
      font-weight: 900;
  }
  .newsletter-container {
      margin-top: 125px;
      display: flex;
  }
  .newsletter-left-column {
      flex-grow: 1;
      padding: 10px;
      margin-top: 20px;
      margin-left: 20px;
      max-width: 160px;
  }
  .newsletter-right-column {
      display: flex;
      flex-grow: 5;
      justify-content: center;
      margin-right: 80px;
  }
  .newsletter-right-column-content {
      margin: 50px 100px;
      border: 3px double #ddd;
      padding: 0px 30px;
      max-width: 595px;
  }
  .newsletter-subtitle {
      font-size: 20px;
      font-weight: 300;
  }
  .newsletter-date {
      font-size: 14px;
      font-weight: 500;
  }
  .newsletter-paragraph {
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
  }
  .newsletter-preview {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      padding-top: 40px;
  }
  .italic {
      font-style: italic;
  }

  @media only screen and (max-width: 750px) {
      h2 {
          font-size: 18px;
      }
      .newsletter-container {
          flex-direction: column;
      }
      .newsletter-right-column {
          margin-right: 0;
      }
      .newsletter-right-column-content {
          margin: 10px 10px;
          padding: 0 10px;
      }
      .newsletter-subtitle {
          font-size: 16px;
      }
      .newsletter-date {
          font-size: 13px;
      }
      .newsletter-paragraph {
          font-size: 14px;
      }
      .newsletter-preview {
          font-size: 13px;
      }
  }
</style>
